<template>
  <v-container
    id="regular-tables"
    tag="section"
  >
    <base-v-component
      heading="Entities List"
      link="components/simple-tables"
    />

    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        xl="8"
      >
        <v-card>
          <v-row class="pa-4 justify-end">
            <entity-create
              @newEntity="handleNewEntity"
            />
          </v-row>

          <v-data-table
            dense
            :headers="headers"
            :items="entities"
            :loading="loading"
            item-key="name"
            class="pt-8"
            loading-text="Loading... Please wait"
            hide-default-footer
            disable-sort
            disable-pagination
          >
            <template v-slot:item.action="{ item }">
              <div class="my-2">
                <v-btn
                  class="primary"
                  small
                  @click="show(item.id)"
                >
                  {{ $t('details') }}
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import EntitiesRepository from '@/api/EntitiesRepository'
import EntityCreate from '@/views/dashboard/components/Entities/EntityCreate'
import { capitalize } from '@/helpers/helpers'

export default {
  name: 'EntitiesList',
  components: { EntityCreate },
  data () {
    return {
      entities: [],
      search: '',
      headers: [
        { text: capitalize(this.$t('name')), value: 'name' },
        { text: capitalize(this.$t('clients')), value: 'clients_count' },
        { text: capitalize(this.$t('created')), value: 'created_at' },
        { text: capitalize(this.$t('actions')), value: 'action', sortable: false, align: 'end' },
      ],
      loading: true,
    }
  },
  watch: {
    search () {
      this.loading = true
      this.getEntities()
    },
  },
  created () {
    this.getEntities()
  },
  methods: {
    getEntities () {
      EntitiesRepository.index({
        search: this.search,
        withCount: 'clients',
      })
        .then(({ data }) => {
          this.entities = data
          this.loading = false
        })
        .catch(() => {
        })
    },
    handleNewEntity () {
      this.loading = true
      this.getEntities()
    },
    show (id) {
      this.$router.push({
        name: 'Entity Orders',
        params: {
          entityId: id,
        },
      })
    },
  },

}
</script>

<style scoped>

</style>
