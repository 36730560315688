<template>
  <v-dialog
    v-model="show"
    width="500"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        class="primary"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('c.createEntity') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t('c.newEntity') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          :label="$t('name') | capitalize"
          :loading="requesting"
          :error-messages="errors.name"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="grey"
          :loading="requesting"
          @click="show=false"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="requesting"
          @click="validate"
        >
          {{ $t('create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EntitiesRepository from '@/api/EntitiesRepository'

export default {
  name: 'EntityCreate',
  data () {
    return {
      name: '',
      show: false,
      requesting: false,
      errors: {
        name: null,
      },
    }
  },
  watch: {
    show () {
      this.name = ''
    },
  },
  methods: {
    validate () {
      this.errors.name = this.name === '' ? 'name is required' : null
      const numErrors = Object.keys(this.errors).filter(key => this.errors[key] !== null)
      if (numErrors.length === 0) {
        this.submit()
      }
    },
    submit () {
      this.requesting = true
      EntitiesRepository.store({ name: this.name })
        .then(({ data }) => {
          this.$emit('newEntity')
          this.show = false
          this.requesting = false
        })
        .catch(({ response }) => {
          this.requesting = false
          if (response.status === 422) {
            const { data } = response
            if (data.errors.name) {
              this.errors.name = data.errors.name[0]
            }
          }
        })
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          newEntity: 'New Entity',
          createEntity: 'New Entity',
        },
      },
      it: {
        c: {
          newEntity: 'Nuova Entità',
          createEntity: 'Creare Entità',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
